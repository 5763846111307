<template>
  <div class="home-view">
    <div>
      <div class="intro-container">
        <div class="main-message-wrapper">
          <div class="main-message-text">割り勘派の、</div>
          <div class="main-message-text">あなたに。</div>
        </div>

        <h1>C O U P L I C A</h1>

        <p>
          パートナーとのお金のやり取りを記録し、円満なお金の管理をサポートします
        </p>

        <div v-if="currentUser" class="mt-16">
          <v-card>
            <v-card-text>ログイン済み</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn :color="cColors.primary" @click="navigateToProfile"
                ><SvgIcon
                  type="mdi"
                  :path="iconPath.mdiHomeAccount"
                  :color="cColors.primary_dark_1"
                ></SvgIcon
                >ホームへ</v-btn
              ></v-card-actions
            >
          </v-card>
        </div>

        <div v-else>
          <div class="login-button mt-16">
            <v-card class="pa-4 mx-4 login-card">
              <v-divider class="my-4"></v-divider>
              <img
                class="google-login-image"
                src="../assets/googleSignIn.png"
                alt=""
                @click="login"
              />
              <v-divider class="my-4"></v-divider>
            </v-card>
          </div>
        </div>
      </div>

      <div
        class="sub-message-wrapper pa-4"
        v-for="content in introductionContexts"
        :key="content.index"
      >
        <v-card
          :color="String(cColors.primary_dark_1) + '80'"
          class="pa-4 sub-message-card"
        >
          <div>
            <v-card-title>{{ content.title }}</v-card-title>
            <v-img :src="content.image"></v-img>
            <v-card-text>{{ content.description }}</v-card-text>
          </div>
        </v-card>
      </div>

      <h3 :color="cColors.primary_dark_1" class="mt-16">Couplicaの機能</h3>

      <div
        class="sub-message-wrapper pa-4"
        v-for="(content, index) in couplicaFeatures"
        :key="index"
      >
        <div>
          <v-card
            :color="String(cColors.primary_dark_1) + '80'"
            class="pa-4 sub-message-card"
          >
            <div class="feature-index-container">
              <div>{{ index + 1 }}</div>
            </div>
            <div>
              <v-card-title>{{ content.title }}</v-card-title>
              <v-img :src="content.image"></v-img>
              <v-card-text>{{ content.description }}</v-card-text>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiHomeAccount } from "@mdi/js";

export default {
  props: ["currentUser", "cColors"],
  data: function () {
    return {
      iconPath: {
        mdiHomeAccount: mdiHomeAccount,
      },
      introductionContexts: [
        {
          title: "同棲とお金の管理",
          description:
            "3組に1組のカップルがお金について揉めたことがあるという調査も",
          image:
            "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmarkus-winkler-QIKifJXni5U-unsplash.jpg?alt=media&token=c3f6ba68-5e78-44fb-812d-b834e20b46e1",
        },
        {
          title: "Couplicaによる問題の解決",
          description:
            "Couplicaを用いて支出を記録し、締め日のタイミングでどちらがいくらを返せばいいのかを算出します",
          image:
            "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmarkus-winkler-QIKifJXni5U-unsplash.jpg?alt=media&token=c3f6ba68-5e78-44fb-812d-b834e20b46e1",
        },
      ],
      couplicaFeatures: [
        {
          title: "予算設定によるお財布の監視",
          description:
            "予算を設定し、使用状況を監視することで無駄遣いを抑えます。",
          image:
            "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmarkus-winkler-QIKifJXni5U-unsplash.jpg?alt=media&token=c3f6ba68-5e78-44fb-812d-b834e20b46e1",
        },
        {
          title: "分析による支出最適化",
          description:
            "Couplicaは締め日ごとにそのタームの支出の統計データを算出します。これを用いて家計を改善させることが可能です。",
          image:
            "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmarkus-winkler-QIKifJXni5U-unsplash.jpg?alt=media&token=c3f6ba68-5e78-44fb-812d-b834e20b46e1",
        },
      ],
    };
  },
  components: {
    SvgIcon,
  },
  methods: {
    login() {
      this.$emit("login");
    },
    navigateToProfile() {
      this.$router.push(`/profile/${this.currentUser.uid}`);
    },
  },
};
</script>

<style scoped>
.home-view {
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/top.jpg");
  /* background-color: #028090; */
  margin: 65px auto auto 0px;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;

  display: flex;
  flex-direction: column;
}
.intro-container {
  height: 100vh;
}
.intro-container h1,
h3 {
  color: rgb(212, 204, 35);
}
.intro-container p {
  /* color: rgb(249, 248, 221); */
  display: inline-block;
  /* background: linear-gradient(90deg, aliceblue , blue 80%,  aliceblue ); */
  background: -webkit-linear-gradient(
    0deg,
    aliceblue,
    rgb(255, 251, 121),
    aliceblue
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.login-card {
  background-color: rgba(255, 255, 255, 0.75);
}
.google-login-image {
  width: 80%;
  height: auto;
  margin-top: 0px;
}
.main-message-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
.main-message-text {
  width: 48%; /* 子要素の幅を指定 (100% - 両方の要素の幅の合計 = 余白) */
  text-align: justify; /* テキストを両端揃えにする */
  height: 10vh;
}
.main-message-text:first-child {
  margin-bottom: 15%;
  margin-right: 2%; /* 最初のテキスト要素に右マージンを追加 */
}
.main-message-text:last-child {
  margin-top: 15%;
  margin-left: 2%; /* 2番目のテキスト要素に左マージンを追加 */
}
.main-message-text {
  text-align: center;
  margin: 0 5vw;
  color: aliceblue;
  font-size: 150%;
  font-weight: bold;
  @media (max-width: 500px) {
    font-size: 5.5vw;
  }
}
.title-wrapper {
  width: 100%;
  height: 10vh;
  background-color: aliceblue;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  font-size: 150%;
}
.sub-message-wrapper {
  color: aliceblue;
}
.feature-index-container {
  position: absolute;
  background-color: #eab841;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  z-index: 10;
  color: black;
  font-size: bold;
}
</style>
