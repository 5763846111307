<template>
    <div class="center-container">
        <img :src="url">
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: ['text', 'width', 'height'],
  setup(props) {
    console.log(props.text)
    let url = ref('https://api.qrserver.com/v1/create-qr-code/?data=');
    url.value += encodeURIComponent(props.text);
    url.value += '&bgcolor=F2F2F2';
    url.value += '&color=111111';
    url.value += '&qzone=0';
    url.value += '&margin=0';
    url.value += '&ecc=L';
    url.value += `&size=${props.width}x${props.height}`;
    return {
      url
    };
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
}

img {
  max-width: 100%;
  max-height: 100%;
}
</style>