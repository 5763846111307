<template>
  <div>
    <v-card class="overflow-y-auto" height="80vh">
      <v-banner class="justify-center text-h5 font-weight-light mt-4" sticky>
        お問い合わせ
      </v-banner>

      <v-card-text>
        <div class="my-2">
          <v-card-title>お問い合わせテンプレート</v-card-title>
          <v-card-text
            >以下のフォームをご活用いただき、お問い合わせください。なお、ご返信にはメールアドレスをご記入いただく必要がございます。ご返信には数日間のお時間をいただく場合がございます。あらかじめご了承ください。</v-card-text
          >
          <div class="ma-4">
            <v-card-text>aa</v-card-text>
            <v-card-title>お問い合わせ先</v-card-title>
            <a href="https://forms.gle/tweDWYkktwfAkawHA"
              >お問い合わせフォーム</a
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>