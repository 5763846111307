<template>
  <div class="payment-card-container">
    <v-card theme="light">
      <div class="payment-card-wrapper">
        <div class="card-img-container">
          <img
            class="card-img-wrapper"
            :src="cImages[translate(subscription.category, 'ja', 'en')]"
            alt=""
          />
        </div>
        <div class="card-info-container">
          <div class="card-info-wrapper">
            <h5>{{ subscription.name }}</h5>
            <p>{{ coupleData[subscription.paiedBy].name }}</p>
            <p>¥ {{ subscription.amount }}</p>
						<p>{{subscription.cron}}</p>
          </div>
          <div v-if="!isReadOnly">
            <v-card-actions>
              <v-btn class="ms-2">
                <SvgIcon
                  type="mdi"
                  :color="cColors.data.edit"
                  :path="iconPath.mdiPencil"
                  @click="onEdit"
                ></SvgIcon
              ></v-btn>
              <v-btn class="ms-2">
                <SvgIcon
                  type="mdi"
                  :color="cColors.data.delete"
                  :path="iconPath.mdiDelete"
                  @click="onDelete"
                ></SvgIcon
              ></v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCurrencyJpy, mdiPencil, mdiDelete } from "@mdi/js";
const translateText = require("../../common/translateText");

export default {
  props: ["subscription", "isReadOnly", "cColors", "coupleData", "cImages"],
  data: function () {
    return {
      iconPath: {
        mdiCurrencyJpy: mdiCurrencyJpy,
        mdiPencil: mdiPencil,
        mdiDelete: mdiDelete,
      },
    };
  },
  components: {
    SvgIcon,
  },
  methods: {
    onEdit(subscription) {
      this.$emit("onEdit", subscription);
    },
    onDelete(subscription) {
      this.$emit("onDelete", subscription);
    },
		translate(word, from, to) {
      return translateText(word, from, to);
    }
  },
};
</script>

<style scoped>
.payment-card-container {
  margin: 10px 0px;
}
.payment-card-wrapper {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-img-container {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img-container img {
  width: 90px;
  height: 90px;
  border-radius: 20px;
}
.card-img-wrapper {
  /* border-radius: 100px; */
}
.card-info-container {
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}
.card-info-wrapper {
  margin-top: 10px;
  margin-bottom: 0px;
}
.card-info-wrapper p {
  margin: 0px;
}
.card-info-wrapper h5 {
  font-size: 15px;
}
/* .payment-card-container {
  margin: 10px 0px;
	height: auto;
}
.payment-card-wrapper {
	width: 90%;
  display: flex;
	justify-content: center;
}
.card-img-container {
  width: 80px;
  height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.card-img-wrapper {
	border-radius: 10px;
}
.card-info-wrapper {
	justify-content: end;
} */
</style>