<template>
  <div class="analysis-view">
    <div v-if="statistics.length > 0">
      <v-card>
        <div class="mt-32">
          <v-slide-group show-arrows>
            <v-slide-group-item
              v-for="statistics in statistics"
              :key="statistics.s_id"
              v-slot="{ isSelected, toggle }"
            >
              <div @click="selectSid(statistics)">
                <v-btn
                  class="ma-2"
                  rounded
                  :color="isSelected ? cColors.primary_dark_1 : undefined"
                  @click="toggle"
                >
                  <p>{{ statistics.s_id.slice(-6) }}</p>
                </v-btn>
              </div>
            </v-slide-group-item>
          </v-slide-group>
        </div>

        <div>
          <v-tabs
            v-model="shownTab_statisticsTarget"
            :color="cColors.accent.dark_1"
            align-tabs="center"
          >
            <v-tab :value="1">{{ coupleData.partner1.name }}</v-tab>
            <v-tab :value="0">全体</v-tab>
            <v-tab :value="2">{{ coupleData.partner2.name }}</v-tab>
          </v-tabs>
          <div v-if="shownTab_statisticsTarget == 0">
            <!-- <h4>カテゴリ別 支出割合</h4> -->
            <v-divider></v-divider>
            <GChart
              type="PieChart"
              :data="chartData.pie.whole"
              :options="chartOptions.pie"
            />
            <!-- <h4>支出額</h4> -->
            <v-divider></v-divider>
            <BalanceConsole
              :balanceData="statistics[chartData.index].settledBalance.whole"
              :cImages="cImages"
              class="pa-4"
            />
          </div>
          <div v-if="shownTab_statisticsTarget == 1">
            <!-- <h4>カテゴリ別 支出割合</h4> -->
            <v-divider></v-divider>
            <GChart
              type="PieChart"
              :data="chartData.pie.partner1"
              :options="chartOptions.pie"
            />
            <!-- <h4>支出額</h4> -->
            <v-divider></v-divider>
            <BalanceConsole
              :balanceData="statistics[chartData.index].settledBalance.partner1"
              :cImages="cImages"
              class="pa-4"
            />
          </div>
          <div v-if="shownTab_statisticsTarget == 2">
            <!-- <h4>カテゴリ別 支出割合</h4> -->
            <v-divider></v-divider>
            <GChart
              type="PieChart"
              :data="chartData.pie.partner2"
              :options="chartOptions.pie"
            />
            <!-- <h4>支出額</h4> -->
            <v-divider></v-divider>
            <BalanceConsole
              :balanceData="statistics[chartData.index].settledBalance.partner2"
              :cImages="cImages"
              class="pa-4"
            />
          </div>
          <div v-if="coupleData_settledPayments.length == 0">
            <v-btn
              class="ma-2"
              rounded
              :color="cColors.primary_dark_1"
              @click="getSettledPayments"
            >
              明細を表示
            </v-btn>
          </div>
          <div v-else>
            <div
              v-for="payment in coupleData_settledPayments"
              :key="payment.pid"
            >
              <PaymentCard
                :payment="payment"
                :isReadOnly="true"
                :cColors="cColors"
                :cImages="cImages"
              />
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else>
      <div>
        <ErrorMessenger
          message="分析対象のデータが存在しません"
          hint="精算を1回以上行うと表示されるようになります"
          :cColors="cColors"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";

import CouplicaLogger from "../../common/CouplicaLogger";
const cLogger = new CouplicaLogger();

import { GChart } from "vue-google-charts";

import PaymentCard from "../components/PaymentCard.vue";
import BalanceConsole from "../components/BalanceConsole.vue";
import ErrorMessenger from "../components/ErrorMessenger.vue";

// import SvgIcon from "@jamescoyle/vue-icon";
// import { mdiHomeAccount } from "@mdi/js";

export default {
  props: ["currentUser", "cColors", "cImages"],
  data: function () {
    return {
      coupleData: null,
      coupleData_settledPayments: [],
      shownTab_statisticsTarget: 0,
      statistics: [],
      chartOptions: {
        pie: {},
      },
      chartData: {
        index: 0,
        pie: {},
      },
      iconPath: {
        // mdiHomeAccount: mdiHomeAccount,
      },
    };
  },
  components: {
    GChart,
    PaymentCard,
    BalanceConsole,
    // SvgIcon,
    ErrorMessenger,
  },
  methods: {
    async getCouple() {
      try {
        const docRef_couple = doc(db, "Couples", this.$route.params.cid);
        const docSnapshot = await getDoc(docRef_couple);
        this.coupleData = docSnapshot.data();
      } catch (err) {
        cLogger.error(500, err.message);
      }
    },
    async getSettledPayments() {
      if (this.coupleData_settledPayments.length == 0) {
        try {
          const colRef_settledPayments = collection(
            db,
            "Couples",
            this.$route.params.cid,
            "payments_settled"
          );
          //orderBy this.coupleData.order
          const docsSnapshot = await getDocs(colRef_settledPayments);
          docsSnapshot.forEach((doc) => {
            this.coupleData_settledPayments.push(doc.data());
          });
        } catch (err) {
          cLogger.error(500, err.message);
        }
      } else {
        cLogger.error(500, "data already fetched.");
      }
    },
    async getStatistics() {
      try {
        const colRef_statistics = collection(
          db,
          "Couples",
          this.$route.params.cid,
          "statistics"
        );
        //orderBy this.coupleData.order
        const docsSnapshot = await getDocs(colRef_statistics);
        docsSnapshot.forEach((doc) => {
          this.statistics.push(doc.data());
        });
        cLogger.info(200, "Statistics Data successfully fethed.");
      } catch (err) {
        cLogger.error(500, err.message);
      }
    },
    formatStatisticsData(statistics) {
      this.chartData.pie.whole = [
        ["Category", "Value"],
        ["日用品費", statistics.settledBalance.whole.dailyGoods],
        ["娯楽費", statistics.settledBalance.whole.entertainment],
        ["食費", statistics.settledBalance.whole.food],
        ["交際費", statistics.settledBalance.whole.social],
        ["家賃", statistics.settledBalance.whole.house],
        ["水道光熱費", statistics.settledBalance.whole.utility],
      ];
      this.chartData.pie.partner1 = [
        ["Category", "Value"],
        ["日用品費", statistics.settledBalance.partner1.dailyGoods],
        ["娯楽費", statistics.settledBalance.partner1.entertainment],
        ["食費", statistics.settledBalance.partner1.food],
        ["交際費", statistics.settledBalance.partner1.social],
        ["家賃", statistics.settledBalance.partner1.house],
        ["水道光熱費", statistics.settledBalance.partner1.utility],
      ];
      this.chartData.pie.partner2 = [
        ["Category", "Value"],
        ["日用品費", statistics.settledBalance.partner2.dailyGoods],
        ["娯楽費", statistics.settledBalance.partner2.entertainment],
        ["食費", statistics.settledBalance.partner2.food],
        ["交際費", statistics.settledBalance.partner2.social],
        ["家賃", statistics.settledBalance.partner2.house],
        ["水道光熱費", statistics.settledBalance.partner2.utility],
      ];
      this.chartOptions.pie = {
        pieHole: 0.4,
        pieSliceText: "percentage",
        sliceVisibilityThreshold: 0.001,
        colors: [
          this.cColors.graph.dailyGoods,
          this.cColors.graph.entertainment,
          this.cColors.graph.food,
          this.cColors.graph.social,
          this.cColors.graph.house,
          this.cColors.graph.utility,
        ],
        backgroundColor: "transparent",
        titleTextStyle: {
          fontSize: 14,
          bold: true,
        },
        legend: {
          position: "bottom", // 凡例の位置
        },
        chartArea: {
          top: "10%", // チャートエリアの左余白
          bottom: "10%", // チャートエリアの上余白
          width: "100%", // チャートエリアの幅
          height: "80%", // チャートエリアの高さ
        },
      };
    },
    getFeatures() {
      console.log(this.statistics);
    },
    async selectSid(statistics) {
      statistics.isSelected = true;
      this.chartData.index = await this.statistics.findIndex((obj) => {
        return obj.s_id == statistics.s_id;
      });
      await this.formatStatisticsData(this.statistics[this.chartData.index]);
    },
    navigateToProfile() {
      this.$router.push(`/profile/${this.currentUser.uid}`);
    },
  },
  async created() {
    try {
      await this.getCouple();
      await this.getStatistics();
      // await this.getFeatures();
      await this.formatStatisticsData(this.statistics[this.chartData.index]);
    } catch (err) {
      cLogger.error(500, err.message);
    }
  },
};
</script>

<style scoped>
.analysis-view {
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/top.jpg");
  margin: 65px auto auto 0px;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>