<template>
  <div class="payment-card-container">
    <v-card
      theme="light"
      :class="{ 'unselected-card-cover': !payment.isSelected }"
    >
      <div class="payment-card-wrapper" @click="$emit('onSelect')">
        <div class="card-img-container">
          <img
            class="card-img-wrapper"
            :src="cImages[translate(payment.category, 'ja', 'en')]"
            alt=""
          />
        </div>
        <div class="card-info-container">
          <div class="card-info-wrapper">
            <h5>{{ payment.name }}</h5>
            <p>{{ coupleData[payment.paiedBy].name }}</p>
            <p>¥ {{ payment.amount }}</p>
            <p>
              {{ new Date(payment.purchasedAt.seconds * 1000).toLocaleDateString("ja-JP") }}
            </p>
          </div>
          <div v-if="!isReadOnly">
            <v-card-actions @click="$emit('onSelect')">
              <v-btn class="ms-2">
                <SvgIcon
                  type="mdi"
                  :color="cColors.data.edit"
                  :path="iconPath.mdiPencil"
                  @click="onEdit"
                ></SvgIcon
              ></v-btn>
              <v-btn class="ms-2">
                <SvgIcon
                  type="mdi"
                  :color="cColors.data.delete"
                  :path="iconPath.mdiDelete"
                  @click="onDelete"
                ></SvgIcon
              ></v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
      <div class="ratio-indicator-container">
        <div
          class="ratio-indicator-partner1"
          :style="{
            width: payment.ratio.partner1 + '%',
            backgroundColor: cColors.primary_dark_1,
          }"
        ></div>
        <div
          class="ratio-indicator-partner2"
          :style="{
            width: payment.ratio.partner2 + '%',
            backgroundColor: cColors.primary_light_1,
          }"
        ></div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCurrencyJpy, mdiPencil, mdiDelete } from "@mdi/js";
const translateText = require("../../common/translateText");

export default {
  props: ["payment", "isReadOnly", "cColors", "coupleData", "cImages"],
  data: function () {
    return {
      iconPath: {
        mdiCurrencyJpy: mdiCurrencyJpy,
        mdiPencil: mdiPencil,
        mdiDelete: mdiDelete,
      },
    };
  },
  components: {
    SvgIcon,
  },
  methods: {
    onEdit(payment) {
      this.$emit("onEdit", payment);
    },
    onDelete(payment) {
      this.$emit("onDelete", payment);
    },
    translate(word, from, to) {
      return translateText(word, from, to);
    },
  },
};
</script>

<style scoped>
.payment-card-container {
  margin: 10px 0px;
  /* position: relative; */
}
.payment-card-wrapper {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-img-container {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img-container img {
  width: 90px;
  height: 90px;
  border-radius: 20px;
}
.card-img-wrapper {
  /* border-radius: 100px; */
}
.card-info-container {
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}
.card-info-wrapper {
  margin-top: 10px;
  margin-bottom: 0px;
}
.card-info-wrapper p {
  margin: 0px;
}
.card-info-wrapper h5 {
  font-size: 15px;
}

.ratio-indicator-container {
  width: 100%;
  height: 3px;
  display: flex;
}
.ratio-indicator-partner1 {
  height: 100%;
}
.ratio-indicator-partner2 {
  height: 100%;
}

.unselected-card-cover {
  background-color: rgba(128, 128, 128, 0.5);
}
</style>