<template>
  <div class="adsense">
    <ins class="adsbygoogle"
         :style="adStyle"
         :data-ad-client="adClient"
         :data-ad-slot="adSlot"
         :data-ad-format="adFormat"
         :data-full-width-responsive="responsive"
         data-ad-layout-key="-fb+5w+4e-db+86">
    </ins>
  </div>
</template>

<script>
export default {
  props: {
    adClient: { type: String, required: false },
    adSlot: { type: String, required: false },
    adFormat: { type: String, default: 'auto' },
    responsive: { type: Boolean, default: true }
  },
  computed: {
    adStyle() {
      return 'display:block';
    }
  },
  mounted() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
}
</script>
