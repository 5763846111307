<template>
  <div>
    <v-card class="overflow-y-auto" height="80vh">
      <v-banner class="justify-center text-h5 font-weight-light mt-4" sticky>
        プライバシーポリシー
      </v-banner>

      <v-card-text>
        <div class="my-2">
          本サービスは、個人情報の保護は弊社が事業活動を行うにあたっての基礎であるとともに、弊社の社会的責任であると認識しております。
          弊社は、弊社の製品やサービスに関する情報をはじめ、お客様からご提供いただいた個人情報等、多種多様な情報を取り扱っております。
          弊社は個人情報保護について、関連規程の制定及び管理体制の確立を図るとともに、以下のとおりプライバシーポリシーを定め、弊社役員及び従業員に周知徹底し、本方針に従って個人情報を適切に利用、管理及び保護することといたします。
          <v-card-text>1. 個人情報の収集、利用及び提供について</v-card-text>
          <v-card-text
            >弊社は、事業活動を行うにあたって、お客様の大切な情報をご提供いただいていることを踏まえ、各業務実態に応じて個人情報を保護するための情報管理体制を確立し、個人情報の収集、利用及び提供に関して所定の規程に則り適切な取り扱いをいたします。</v-card-text
          >
          <v-card-text>2. 法令の遵守について</v-card-text>
          <v-card-text
            >弊社は、個人情報の取り扱いに関して、法令を遵守いたします。
          </v-card-text>

          <v-card-text>3. 安全対策について</v-card-text>
          <v-card-text>
            弊社は、個人情報を保護するため、情報セキュリティに関する規程に基づき、当該個人情報の管理、個人情報の持ち出し方法の指定、第三者からの不正アクセスの防止等の対策を行い、個人情報の漏洩、紛失、改ざん、破壊等の予防を図ります。
          </v-card-text>
          <v-card-text>4. ご本人様の要請について</v-card-text>
          <v-card-text>
            弊社は、個人情報に関して、ご本人様から当該個人情報の開示、訂正、削除、利用または提供について何らかの要請を受けた場合、誠実に対応いたします。
          </v-card-text>
          <v-card-text>5. 個人情報管理規程について</v-card-text>
          <v-card-text>
            弊社は、弊社役員及び従業員に対し、個人情報を保護する重要性と必要性の理解促進を図り、個人情報の適切な管理を図るため、個人情報管理規程を定め、当該規程を遵守いたします。
          </v-card-text>

          <v-card-title>個人情報の利用目的</v-card-title>
          <v-card-text
            >弊社は、事業活動に関連してご提供いただく（またはご提供いただいている）個人情報に関して、以下の目的に該当する場合のみ利用いたします。</v-card-text
          >
          <v-card-text
            >弊社製品及びサービス情報等に関するご案内のため</v-card-text
          >
          <v-card-text
            >弊社製品及びサービス情報等についてのお問い合わせに対する回答連絡のため</v-card-text
          >
          <v-card-text
            >弊社製品及びサービス情報等についてのアフターサービスなど取引業務遂行のため</v-card-text
          >
          <v-card-text
            >弊社製品及びサービスの販売またはご提供を目的とするご本人様確認のため</v-card-text
          >
          <v-card-text
            >弊社製品及びサービス情報等についてのアンケート調査及びモニター調査のため</v-card-text
          >
          <v-card-text
            >弊社製品及びサービス情報等についてのマーケティング活動のため</v-card-text
          >
          <v-card-text
            >その他弊社製品及びサービス情報等のご提供に必要な場合</v-card-text
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>