module.exports = function translateText(text, from, to) {
	const mapping_en_ja = {
		food: "食費",
		entertainment: "娯楽費",
		house: "家賃",
		dailyGoods: "日用品費",
		social: "交際費",
		utility: "光熱費"
	}
	if (from == "en" && to == "ja") {
		return mapping_en_ja[text];
	}
	if (from == "ja" && to == "en") {
		return Object.keys(mapping_en_ja).filter((key) => {
			return mapping_en_ja[key] === text
		});
	}
}