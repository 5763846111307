class Payment {
    amount = 0;
    category = "";
    cid = "";
    purchasedAt = "";
    detail = "";
    name = "";
    isPaiedOff = false;
    paiedBy = "";
    pid = "";
    ratio = {
        partner1: null,
        partner2: null
    };
    uids = [];
    purchasedMonth = "";

	constructor(coupleData, cid) {
        this.ratio.partner1 = coupleData.partner1.ratio;
        this.ratio.partner2 = coupleData.partner2.ratio;
        this.uids = coupleData.uids;
        this.purchasedAt = new Date();
        this.purchasedMonth = String(new Date().toLocaleDateString("ja-JP", {year: "numeric", month: "2-digit", day: "2-digit"})).slice(0, -3);
        this.cid = cid;
        this.pid = `p_${Math.random().toString(32).substring(2)}`;
	}

    // getCategoryImage() {
    //     switch(this.category) {
    //         case '食費': 
    //             return `https://d3d7exujemgi7m.cloudfront.net/upload/recipe/2021/09/615564ffc4cd9.jpg`;
    //         default:
    //             return `https://cdn.vuetifyjs.com/images/cards/halcyon.png`;
    //     }
    // }
	
}

export default Payment;