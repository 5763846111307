class CouplicaLogger {
	constructor() {

	}
	info(status, message) {
		switch (status) {
			case 200:
				console.info(`[INFO : ${status}-OK] ==> ${message}`);
				break;
			case 201:
				console.info(`[INFO : ${status}-Created] ==> ${message}`);
				break;
			default:
				console.info(`[INFO : ${status}-XXX] ==> ${message}`)
		}
	}
	error(status, message) {
		switch (status) {
			case 500:
				console.error(`[ERROR : ${status}-Internal Server Error] ==> ${message}`);
				break;
			default:
				console.error(`[ERROR : ${status}-XXX] ==> ${message}`)
		}
	}
}

export default CouplicaLogger;