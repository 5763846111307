<template>
  <div>
		<v-file-input label="プロフィール画像" @change="handleFileUpload"></v-file-input>
    <!-- <div v-if="imageUrl">
      <img :src="imageUrl" alt="Uploaded Image" width="200" />
    </div> -->
  </div>
</template>

<script>
import { storage } from "../../firebase";
import {
	uploadBytes,
  getDownloadURL,
  ref
} from "firebase/storage";

export default {
  name: "ImageUploader",
  props: ["currentUser"],
	data: function() {
		return {
			selectedFile: null,
			imageUrl: null
		}
	},
	methods: {
		async handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
      await this.uploadFile();
    },
    async uploadFile() {
      if (!this.selectedFile) {
        return;
      }
      const storageRef = ref(storage, `${this.currentUser.uid}/${this.selectedFile.name}`);
      await uploadBytes(storageRef, this.selectedFile);
      this.imageUrl = await getDownloadURL(storageRef);
			this.$emit("onImageUpload", this.imageUrl);
    },
	},
  created() {
    console.log(this.currentUser);
  },
};
</script>
