<template>
  <div>
    <v-card class="overflow-y-auto" height="80vh">
      <v-banner class="justify-center text-h5 font-weight-light mt-4" sticky>
        ご利用規約
      </v-banner>

      <v-card-text>
        <div class="my-2">  
            <div v-for="(term,index) in terms" :key="index">
                <v-card-title>第{{index+1}}条</v-card-title>
                <v-card-title>{{term.title}}</v-card-title>
                <v-card-text v-for="content in term.contents" :key="content">{{content}}</v-card-text>
            </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
        terms: [
            {
                title: "利用規約の受け入れ",
                contents: [
                    "これらの利用規約（以下「利用規約」）は、[サービス名]（以下「当社」または「サービス提供者」）が提供する[サービス名]（以下「サービス」）の利用条件を規定します。本サービスを利用するには、これらの利用規約に同意していただく必要があります。利用者（以下「ユーザー」または「あなた」）は、本サービスを利用する前に、本利用規約をよくお読みいただき、同意していただく必要があります。"
                ]
            },
            {
                title: "外部アカウントサービスとの連携",
                contents: [
                    "利用者は、他社が運営するサービスのID・パスワード（以下「外部アカウント」という）を使用し、当該外部サービスに登録された利用者に関する情報（以下「外部サービス登録情報」という）を本サイトに提供することに同意することによって、本サービスの会員登録手続きを行うことができます。",
                    "会員は、外部サービス登録情報を、当該外部サービスが本サイトに提供することに同意することによって、外部アカウントを使用して本サイトにログイン（以下「外部アカウント連携」という）することができます。",
                    "当社は、前二項により取得する外部サービス登録情報を、別途本サイトに掲載する「個人情報保護について（プライバシー・ポリシー）」に従って取扱います。なお、外部アカウント連携にあたり、会員が入力する外部アカウントは、当該外部サービスのみに送信され、当社は取得しません。",
                    "外部サービスの利用には、当該外部サービスの運営者が別途定める規約への同意が必要になる場合があります。当社は、外部サービスについて、一切の保証を行わず、またいかなる義務又は責任も負いません。"
                ]
            },
            {
                title: "プライバシー",
                contents: [
                    "ユーザーのプライバシーについては、当社のプライバシーポリシーが適用されます。"
                ]
            },
            {
                title: "免責事項",
                contents: [
                    "当社は、本サービスの利用に関連して発生した損害に対して一切の責任を負いません。ユーザーの利用は自己責任で行っていただく必要があります。"
                ]
            },
            {
                title: "広告の掲載",
                contents: [
                    "利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。"
                ]
            },
        ]
    };
  },
};
</script>