class Subscription {
    amount = 0;
    category = "";
    cron = "";
    cid = "";
    detail = "";
    name = "";
    paiedBy = "";
    subid = "";
    ratio = {
        partner1: null,
        partner2: null
    };
    uids = [];

	constructor(coupleData, cid) {
        this.ratio.partner1 = coupleData.partner1.ratio;
        this.ratio.partner2 = coupleData.partner2.ratio;
        this.uids = coupleData.uids;
        this.cid = cid;
        this.subid = `sub_${Math.random().toString(32).substring(2)}`;
	}
	
}

export default Subscription;