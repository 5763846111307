<template>
  <div class="profile-view">
    <div v-if="currentUserData">
      <div v-if="currentUserData.cid">
        <div>
          <v-alert
            v-if="currentUserData.PIN.isValid"
            border="start"
            border-color="orange accent-4"
            elevation="2"
            class="mb-4 pa-0"
          >
            <v-card-text
              >「セキュリティ」タブの連携の許可を無効化して下さい。有効化したままにすると、第三者によりパートナーとの連携が上書きされる可能性があります。</v-card-text
            >
          </v-alert>

          <v-card>
            <div class="profile-header-container">
              <div class="profile-image-contianer">
                <div class="profile-image-wrapper">
                  <img
                    :src="currentUserData.image"
                    alt="プロフィール画像"
                    class="profile-image"
                  />
                </div>
              </div>
              <div>
                <div class="name-wrapper">
                  <h2>{{ currentUserData.name }}</h2>
                </div>
              </div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialog_edit_visible = true">
                <SvgIcon
                  type="mdi"
                  :path="iconPath.mdiPencil"
                  :color="cColors.gray_light_1"
                ></SvgIcon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <v-dialog v-model="dialog_edit_visible" width="auto">
          <v-card width="80vw">
            <v-card-text>
              <v-text-field
                type="text"
                label="お名前"
                v-model="currentUserData.name"
              ></v-text-field>
            </v-card-text>

            <ImageUploader
              :currentUser="currentUser"
              @onImageUpload="setImageUrl"
            />

            <v-card-actions>
              <v-btn :color="cColors.primary" block @click="updateUserData"
                >保存する</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card class="mt-16">
          <v-card-actions>
            <v-tabs
              v-model="shownTab_profileMenu"
              :color="cColors.accent.dark_1"
              align-tabs="center"
            >
              <v-tab :value="0">一般</v-tab>
              <v-tab :value="2">セキュリティ</v-tab>
            </v-tabs>
          </v-card-actions>

          <v-card-item>
            <div v-if="shownTab_profileMenu == 0">一般の設定内容</div>
            <div v-if="shownTab_profileMenu == 2">
              <v-switch
                v-model="PIN_validity"
                :color="cColors.primary"
                label="パートナー接続を許可する"
                @click="togglePINValidity"
              ></v-switch>
              <div v-if="PIN_validity">
                <v-card-title>パートナー接続</v-card-title>
                <v-card-text
                  >パートナーのスマートフォンのカメラでQRを読み取りましょう。</v-card-text
                >
                <div class="d-flex justify-center mt-2 mb-2">
                  <QrCodeDom
                    :text="`https://couplica-div.web.app/register/${currentUserData.cid}:${this.currentUserData.PIN.value}`"
                    width="200"
                    height="200"
                  />
                </div>
              </div>
              <div v-else>
                <v-card-text>パートナー接続が無効化されています</v-card-text>
              </div>
            </div>
          </v-card-item>
        </v-card>
      </div>
      <div v-else>
        <div v-if="this.cid">
          <!-- お財布作成直後 -->
          <v-card>
            <QrCodeDom
              :text="`https://couplica-div.web.app/register/${this.cid}:${this.currentUserData.PIN.value}`"
              width="200"
              height="200"
            />
            <v-card-title>パートナーと接続</v-card-title>
            <v-card-text
              >パートナーのスマートフォンのカメラでQRを読み取りましょう。</v-card-text
            >
          </v-card>
        </div>
        <div v-else>
          <!-- 初期ログイン -->
          <v-card class="pa-4 mb-4">
            <v-img
              width="100%"
              class="mb-4"
              aspect-ratio="16/9"
              cover
              src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
            ></v-img>
            <v-card-title>Couplica</v-card-title>
            <v-card-text>カップルのための家計管理ツール</v-card-text>
            <v-btn
              @click="createCouple"
              :color="cColors.primary_dark_1"
              variant="tonal"
            >
              <SvgIcon
                type="mdi"
                :path="iconPath.mdiHandClap"
                :color="cColors.primary_dark_1"
              ></SvgIcon>
              はじめる！
            </v-btn>
          </v-card>
          <v-card class="pa-4 mt-4">
            <v-img
              width="100%"
              class="mb-4"
              aspect-ratio="16/9"
              cover
              src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
            ></v-img>
            <v-card-text>お金のやり取りが多くて大変！</v-card-text>
          </v-card>
          <v-card class="pa-4">
            <v-card-action>
              <v-btn
                @click="createCouple"
                :color="cColors.primary_dark_1"
                variant="tonal"
              >
                <SvgIcon
                  type="mdi"
                  :path="iconPath.mdiHandClap"
                  :color="cColors.primary_dark_1"
                ></SvgIcon>
                はじめる！
              </v-btn>
            </v-card-action>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- 読み込み中 -->
      <div class="mt-16">
        <v-progress-linear
          :color="cColors.accent.dark_1"
          height="12"
          indeterminate
        ></v-progress-linear>
        <p class="text-white font-weight-bold">読み込み中...</p>
        <v-btn @click="getUser" :color="cColors.primary_dark_1" class="mt-16">読み込まれない場合はこちら</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import CouplicaLogger from "../../common/CouplicaLogger";
const cLogger = new CouplicaLogger();

import QrCodeDom from "../components/QRCodeDom.vue";
import ImageUploader from "../components/ImageUploader.vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPencil, mdiHandClap } from "@mdi/js";

export default {
  props: ["currentUser", "cColors"],
  data: function () {
    return {
      shownTab_profileMenu: 0,
      dialog_edit_visible: false,
      PIN_validity: false,
      currentUserData: null,
      cid: null,
      iconPath: {
        mdiPencil: mdiPencil,
        mdiHandClap: mdiHandClap,
      },
    };
  },
  components: {
    QrCodeDom,
    SvgIcon,
    ImageUploader,
  },
  methods: {
    async getUser() {
      const docRef = doc(db, "Users", this.currentUser.uid);
      const docSnap = await getDoc(docRef);
      this.currentUserData = docSnap.data();
      this.PIN_validity = this.currentUserData.PIN.isValid;
    },
    async togglePINValidity() {
      try {
        this.PIN_validity = !this.PIN_validity;
        const updateData_user = this.currentUserData;
        updateData_user.PIN.isValid = this.PIN_validity;
        await updateDoc(
          doc(db, "Users", this.currentUser.uid),
          updateData_user
        );
        cLogger.info(200, "Updated PIN Validity");
      } catch (err) {
        cLogger.error(500, err.message);
      }
    },
    async createCouple() {
      try {
        const randomStr_cid = (
          new Date().getTime() + Math.floor(Math.random() * 1010101010101)
        ).toString(16);

        this.cid = "c_" + randomStr_cid;

        const newData_couple = {
          isActivated: false,
          PIN: this.currentUserData.PIN.value,
          emails: [this.currentUserData.email],
          order: "desc",
          partner1: {
            name: this.currentUserData.name,
            ratio: 50,
          },
          partner2: {
            name: null,
            ratio: 50,
          },
          budgets: {
            "isEnabled": false,
            "food": 0,
            "house": 0,
            "entertainment": 0,
            "social": 0,
            "utility": 0,
            "dailyGoods": 0
          },
          subscriptions: {
            isEnabled: false
          },
          uids: [this.currentUserData.uid],
        };
        await setDoc(doc(db, "Couples", this.cid), newData_couple);
        cLogger.info(201, `お財布(${this.cid})を作成しました`);

        const docRef_user = doc(db, "Users", this.currentUser.uid);
        const updateData_user = {
          cid: this.cid,
        };
        await updateDoc(docRef_user, updateData_user);
        cLogger.info(201, `ユーザー(${this.currentUser.uid})を作成しました`);
        this.reload();
      } catch (err) {
        cLogger.error(500, `${err.message}`);
      }
    },
    async updateUserData() {
      try {
        const updateData_user = {
          name: this.currentUserData.name,
          image: this.currentUserData.image,
        };
        await updateDoc(
          doc(db, "Users", this.$route.params.uid),
          updateData_user
        );

        const docSnap_couple = await getDoc(
          doc(db, "Couples", this.currentUserData.cid)
        );
        const coupleData = docSnap_couple.data();

        const indexInCouple_currentUser = coupleData.uids.indexOf(
          this.$route.params.uid
        );
        switch (indexInCouple_currentUser) {
          case 0:
            coupleData.partner1.name = this.currentUserData.name;
            break;
          case 1:
            coupleData.partner2.name = this.currentUserData.name;
            break;
          default:
            cLogger.error(
              500,
              "お財布に紐付けられたユーザのインデックスが見つかりません。"
            );
            break;
        }

        await updateDoc(
          doc(db, "Couples", this.currentUserData.cid),
          coupleData
        );
      } catch (err) {
        cLogger.error(500, err.message);
      }
      this.dialog_edit_visible = false;
    },
    setImageUrl(imageUrl) {
      this.currentUserData.image = imageUrl;
    },
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
  async created() {
    await this.getUser();
  },
};
</script>

<style scoped>
.profile-view {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/top.jpg");
  margin: 65px auto auto 0px;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
.profile-image-contianer {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.main-message-wrapper {
  display: flex;
}
.main-message-text {
  text-align: center;
  margin: 0 5vw;
  color: aliceblue;
  font-size: 26px;
  font-weight: bold;
}
</style>