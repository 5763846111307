<template>
  <div>
    <v-card class="overflow-y-auto" height="80vh">
      <v-banner class="justify-center text-h5 font-weight-light mt-4" sticky>
        運営者について
      </v-banner>
    </v-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>