import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC_lMapiN6v8EcepZ0tACNNAOBlMLxTkdA",
    authDomain: "couplica-div.firebaseapp.com",
    projectId: "couplica-div",
    storageBucket: "couplica-div.appspot.com",
    messagingSenderId: "889768617462",
    appId: "1:889768617462:web:e0264b8f115717478d53d7",
    measurementId: "G-B8RNCXBG3Z"
  };

// firebaseConfigの値を元にfirebaseの初期化
const app = initializeApp(firebaseConfig);
// アナリティクスの初期化
getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { db, auth, storage };
