<template>
  <div>
    <v-sheet>
      <div class="d-flex justify-center ma-2 flex-wrap">
        <div v-for="(balance, index) in balanceData" :key="index">
          <v-card
            height="100px"
            width="100px"
            v-if="index != 'total' && index != 'debt'"
          >
            <v-img
              v-if="index == 'food'"
              :src="cImages[index]"
              class="align-end"
              height="100px"
              width="100px"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
            <v-img
              v-if="index == 'house'"
              :src="cImages[index]"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
              width="100px"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
            <v-img
              v-if="index == 'entertainment'"
              :src="cImages[index]"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
              width="100px"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
            <v-img
              v-if="index == 'utility'"
              :src="cImages[index]"
              class="align-end"
              height="100px"
              width="100px"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
            <v-img
              v-if="index == 'social'"
              :src="cImages[index]"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
              width="100px"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
            <v-img
              v-if="index == 'dailyGoods'"
              :src="cImages[index]"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
              width="100px"
              cover
            >
              <v-card-title class="text-white">
                {{ translate(index, "en", "ja") }}</v-card-title
              >
              <v-card-text class="text-white">¥ {{ balance }}</v-card-text>
            </v-img>
          </v-card>
        </div>
      </div>
      <v-card class="px-4">
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn> 合計： ¥ {{ balanceData.total }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
const translateText = require("../../common/translateText");

export default {
  props: ["balanceData", "cImages"],
  data: function () {
    return {};
  },
  methods: {
    translate(word, from, to) {
      return translateText(word, from, to);
    },
  },
};
</script>