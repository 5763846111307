<template>
    <div>
        NOT FOUND
    </div>
</template>

<script>
export default {
    data: function() {
        return {

        }
    }
}
</script>