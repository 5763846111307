import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/HomeView.vue'
import CoupleHome from './views/CoupleHomeView.vue'
import Profile from './views/ProfileView.vue'
import Register from './views/RegisterView.vue'
import Analysis from './views/AnalysisView.vue'
import PrivacyPolicy from './views/PrivacyPolicyView.vue'
import TermsOfUse from './views/TermsOfUseView.vue'
import ContactUs from './views/ContactUsView.vue'
import AboutUs from './views/AboutUsView.vue'
import NotFound404View from './views/NotFound404View.vue'


const routes = [
  { path: '/', name: 'Home', component: Home, meta: { title: 'Couplica | ホーム', desc: 'Couplica | ホーム' }},
  { path: '/couple/:cid/analysis', name: 'Analysis', component: Analysis, meta: { title: 'Couplica | 支出分析', desc: 'Couplica | 支出分析' }},
  { path: '/couple/:cid', name: 'CoupleHome', component: CoupleHome, meta: { title: 'Couplica | 支出管理', desc: 'Couplica | 支出管理' }},
  { path: '/profile/:uid', name: 'Profile', component: Profile, meta: { title: 'Couplica | マイページ', desc: 'Couplica | マイページ' }},
  { path: '/register/:credential', name: 'Register', component: Register, props: true, meta: { title: 'Couplica | カップル登録', desc: 'Couplica | カップル登録' }},
  { path: '/privacypolicy', name: 'PrivacyPolicy', component: PrivacyPolicy, meta: { title: 'Couplica |プライバシーポリシー', desc: 'Couplica | プライバシーポリシー' }},
  { path: '/termsofuse', name: 'TermsOfUse', component: TermsOfUse, meta: { title: 'Couplica | ご利用規約', desc: 'Couplica | ご利用規約' }},
  { path: '/contactus', name: 'ContactUs', component: ContactUs, meta: { title: 'Couplica | お問い合わせ', desc: 'Couplica | お問い合わせ' }},
  { path: '/aboutus', name: 'AboutUs', component: AboutUs, meta: { title: 'Couplica | 運営について', desc: 'Couplica | 運営について' }},
  { path: '/:catchAll(.*)', component: NotFound404View, meta: { title: 'Couplica | 不明なページ', desc: 'Couplica | ページが見つかりません' } }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router