<template>
  <div>
    <v-card>
      <v-card-text>{{message}}</v-card-text>
			<v-card-text>{{hint}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer
        ><v-btn :color="cColors.primary" @click="navigateToProfile"
          ><SvgIcon
            type="mdi"
            :path="iconPath.mdiHomeAccount"
            :color="cColors.primary_dark_1"
          ></SvgIcon
          >ホームへ</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiHomeAccount } from "@mdi/js";

export default {
  props: ["cColors", "message", "hint"],
	components: {
		SvgIcon
	},
  data: function () {
    return {
			iconPath: {
				mdiHomeAccount: mdiHomeAccount
			}
		};
  },
};
</script>