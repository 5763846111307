<template>
  <div class="register-view">
    <div v-if="currentUser">
      <div v-if="coupleIsActivated">
        <v-btn @click="navigateToCoupleHome(currentUser.uid)"
          >お財布を開く</v-btn
        >
      </div>
      <div v-else>
        <div class="mt-16">
        <v-progress-linear
          :color="cColors.accent.dark_1"
          height="12"
          indeterminate
        ></v-progress-linear>
        <p class="text-white font-weight-bold">パートナーに接続中</p>
        <v-btn @click="decodeCredential(credential)" :color="cColors.primary_dark_1" class="mt-16">ポップアップが出ない場合はこちら</v-btn>
      </div>
    </div>
    </div>
    <div v-else>
      <div>
        <v-card>
          <v-card-text
            >ログインしてパートナー接続を完了させましょう！</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer
            ><v-btn :color="cColors.primary" @click="login"
              ><SvgIcon
                type="mdi"
                :path="iconPath.mdiHomeAccount"
                :color="cColors.primary_dark_1"
              ></SvgIcon
              >ログインする</v-btn
            ></v-card-actions
          >
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import CouplicaLogger from "../../common/CouplicaLogger";
const cLogger = new CouplicaLogger();

import { db } from "../../firebase";
import { doc, arrayUnion, getDoc, updateDoc } from "firebase/firestore";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiHomeAccount } from "@mdi/js";

export default {
  props: ["currentUser", "credential", "cColors"],
  data: function () {
    return {
      coupleIsActivated: false,
      iconPath: {
        mdiHomeAccount: mdiHomeAccount,
      },
    };
  },
  compoents: {
    SvgIcon,
  },
  methods: {
    async decodeCredential(str) {
      const cid = str.substr(0, str.indexOf(":"));
      const PIN_partner = str.substr(str.indexOf(":") + 1);
      const docRef_couple = doc(db, "Couples", cid);
      const docSnap = await getDoc(docRef_couple);
      if (
        docSnap.data().PIN == PIN_partner &&
        docSnap.data().isActivated == false
      ) {
        try {
          const confirm_connection = confirm(
            `${docSnap.data().partner1.name} と接続しますか？`
          );
          if (confirm_connection) {
            const docRef_user = doc(db, "Users", this.currentUser.uid);
            const updateData_user = {
              cid: cid,
            };
            await updateDoc(docRef_user, updateData_user);

            const updateData_couple = {
              emails: arrayUnion(this.currentUser.email),
              uids: arrayUnion(this.currentUser.uid),
              partner2: {
                name: this.currentUser.displayName,
                ratio: 50,
              },
              isActivated: true,
            };
            await updateDoc(docRef_couple, updateData_couple);
            this.coupleIsActivated = true;
          } else {
            alert("キャンセルしました");
          }
        } catch (err) {
          alert(err);
        }
      } else {
        alert("認証情報が一致しません");
        cLogger.error(500, "お財布が無効もしくは認証情報が一致しません");
      }
    },
    async navigateToCoupleHome(uid) {
      const docRef = doc(db, "Users", uid);
      const docSnap = await getDoc(docRef);
      const cid = docSnap.data().cid;
      if (cid) {
        this.$router.push(`/couple/${cid}`);
      } else {
        cLogger.error(404, `カップルIDが見つかりません`);
      }
    },
    login() {
      this.$emit("login", this.credential);
    },
  },
  created() {
    this.decodeCredential(this.credential);
  },
};
</script>

<style scoped>
.register-view {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/top.jpg");
  margin: 65px auto auto 0px;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
</style>