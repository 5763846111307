<template>
  <div id="app">
    <div class="header-view">
      <v-layout>
        <v-app-bar :color="cColors.primary">
          <template v-slot:image>
            <v-img gradient="to bottom right, #0b88a8, #00111d"></v-img>
          </template>

          <!-- <v-btn @click="$router.back()"> </v-btn> -->
          <v-app-bar-title class="app-title pl-0" @click="staticNavTo('/')"
            >Couplica</v-app-bar-title
          >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn v-if="currentUser">
            <SvgIcon
              type="mdi"
              @click="menu_visible = true"
              :path="iconPath.mdiMenu"
            ></SvgIcon>
          </v-btn>
        </v-app-bar>
      </v-layout>
      <v-dialog v-model="menu_visible" width="90vw">
        <v-card class="pa-4">
          <v-btn
            class="my-2"
            @click="navigateToCoupleHome(currentUser.uid)"
            variant="tonal"
            >お財布の管理</v-btn
          >
          <v-btn
            class="my-2"
            @click="navigateToCoupleAnalysis(currentUser.uid)"
            variant="tonal"
            >お財布の分析</v-btn
          >
          <v-btn
            class="my-2"
            @click="navigateToProfile(currentUser.uid)"
            variant="tonal"
            >マイページ</v-btn
          >
          <v-btn class="my-2" @click="logout">ログアウト</v-btn>
        </v-card>
      </v-dialog>
    </div>

    <div class="router-view">
      <router-view
        :currentUser="currentUser"
        :cColors="cColors"
        :cImages="cImages"
        @login="login"
      />
      <div class="ad-container pa-4 mt-16 w-100" v-if="ads_visible">
        <GoogleAdsense />
        <v-btn
          block
          class="ad-container-close"
          @click="ads_visible = false"
          variant="tonal"
        >
          <SvgIcon
            type="mdi"
            :color="cColors.data.delete"
            :path="iconPath.mdiWindowClose"
          ></SvgIcon>
        </v-btn>
      </div>
    </div>
    <v-layout>
      <v-footer class="bg-grey-lighten-1">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="footerLink in footerLinks"
            :key="footerLink.name"
            color="white"
            variant="text"
            class="mx-2"
            rounded="xl"
            @click="staticNavTo(footerLink.url)"
          >
            {{ footerLink.name }}
          </v-btn>
          <v-col class="text-center mt-4" cols="12">
            {{ new Date().getFullYear() }} — <strong>Couplica</strong>
          </v-col>
        </v-row>
      </v-footer>
    </v-layout>

    <!-- <div v-if="currentUser">
      <v-layout class="overflow-visible" style="height: 56px">
        <v-bottom-navigation v-model="value" active v-if="currentUser" grow>
          <v-btn @click="navigateToCoupleAnalysis(currentUser.uid)">
            <SvgIcon
              type="mdi"
              :color="cColors.primary"
              :path="iconPath.mdiPoll"
            ></SvgIcon>
            お財布の分析
          </v-btn>
          <v-btn @click="navigateToCoupleHome(currentUser.uid)">
            <SvgIcon
              type="mdi"
              :color="cColors.primary"
              :path="iconPath.mdiCashMultiple"
            ></SvgIcon>
            お財布の管理
          </v-btn>
          <v-btn @click="navigateToProfile(currentUser.uid)">
            <SvgIcon
              type="mdi"
              :color="cColors.primary"
              :path="iconPath.mdiAccountCircleOutline"
            ></SvgIcon>
            マイページ
          </v-btn>
        </v-bottom-navigation>
      </v-layout>
    </div> -->
  </div>
</template>

<script>
import CouplicaLogger from "../common/CouplicaLogger";
const cLogger = new CouplicaLogger();

import { db, auth } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

import { onAuthStateChanged, signOut } from "firebase/auth";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
const provider = new GoogleAuthProvider();

import GoogleAdsense from "../src/components/GoogleAdsense.vue";

// icon
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiMenu,
  mdiPoll,
  mdiCashMultiple,
  mdiAccountCircleOutline,
  mdiWindowClose,
} from "@mdi/js";

export default {
  name: "App",
  components: {
    SvgIcon,
    GoogleAdsense,
  },
  data: function () {
    return {
      loading: false,
      ads_visible: false,
      menu_visible: false,
      currentUser: {
        isLoggedIn: false,
        coupleId: null,
      },
      iconPath: {
        mdiMenu: mdiMenu,
        mdiPoll: mdiPoll,
        mdiCashMultiple: mdiCashMultiple,
        mdiAccountCircleOutline: mdiAccountCircleOutline,
        mdiWindowClose: mdiWindowClose,
      },
      cColors: {
        primary: "#0b88a8",
        primary_dark_1: "#028090",
        primary_light_1: "#02C39A",
        secondary: "#00111d",
        accent: {
          dark_1: "#eab841",
          light_1: "#F0F3BD",
        },
        gray_light_1: "#637081",
        graph: {
          dailyGoods: "#66cdaa",
          entertainment: "#c71585",
          food: "#dc143c",
          social: "#191970",
          house: "#3cb371",
          utility: "#00bfff",
        },
        data: {
          delete: "#C2185B",
          edit: "#0097A7",
        },
      },
      cImages: {
        dailyGoods:
          "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmarkus-winkler-QIKifJXni5U-unsplash.jpg?alt=media&token=c3f6ba68-5e78-44fb-812d-b834e20b46e1",
        entertainment:
          "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fmae-mu-TnwP3VdUCUA-unsplash.jpg?alt=media&token=c33af9b4-1fce-48f8-a170-2965dd849189",
        food: "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Famirali-mirhashemian-sc5sTPMrVfk-unsplash.jpg?alt=media&token=165d9566-b828-48bc-b751-e609cc256bb6",
        social:
          "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Flucas-ludwig-igBV014C1eM-unsplash.jpg?alt=media&token=554ac56d-eb95-4201-b841-082def5de19e",
        house:
          "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Ftierra-mallorca-rgJ1J8SDEAY-unsplash.jpg?alt=media&token=bb3074e5-d4ce-4bb3-a976-04dceb9e01a0",
        utility:
          "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2FcategoryImage%2Fjose-g-ortega-castro-5joWliaASfY-unsplash.jpg?alt=media&token=c9dedb70-7309-472f-bd3f-d16ccbb43b92",
      },
      footerLinks: [
        {
          name: "ホーム",
          url: "/",
        },
        {
          name: "プライバシーポリシー",
          url: "/privacypolicy",
        },
        {
          name: "ご利用規約",
          url: "/termsofuse",
        },
        {
          name: "お問い合わせ",
          url: "/contactus",
        },
        {
          name: "運営",
          url: "/aboutus",
        },
      ],
    };
  },
  methods: {
    async login(registerCredential) {
      cLogger.info(0, `ログインが施行されています：${registerCredential}`);
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result.user;

          // ユーザーの存在チェック
          const userExists = await this.userExists(user);
          console.log("exists: ", userExists);

          if (userExists) {
            try {
              this.$router.push(`/profile/${user.uid}`);
              return;
            } catch (err) {
              cLogger.error(500, `${err.message}`);
              return null;
            }
          } else {
            // ユーザー登録
            try {
              const newUserData = {
                uid: user.uid,
                cid: null,
                email: user.email,
                name: user.displayName,
                image:
                  "https://firebasestorage.googleapis.com/v0/b/couplica-div.appspot.com/o/default%2Fanonymous_user_blue.png?alt=media&token=8eabf50f-dc6a-4fcd-aa83-9807b91a86f0",
                PIN: {
                  isValid: true,
                  value: Math.random().toString(32).substring(2),
                },
              };
              const userAdded = await setDoc(
                doc(db, "Users", user.uid),
                newUserData
              );
              cLogger.info(200, `${userAdded}`);

              if (registerCredential) {
                this.$router.push(`/register/${registerCredential}`);
              } else {
                this.$router.push(`/profile/${user.uid}`);
              }

              return null;
            } catch (err) {
              cLogger.error(500, `${err}`);
              return null;
            }
          }
        })
        .catch((err) => {
          cLogger.error(500, `${err}`);
        });
    },
    userExists: async function (user) {
      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        cLogger.info(
          200,
          "Detected user document. Redirecting to user profle."
        );
        return true;
      } else {
        cLogger.info(201, "No such user. Starting creating user document...");
        return false;
      }
    },
    navigateToProfile(uid) {
      this.$router.push(`/profile/${uid}`);
      this.menu_visible = false;
    },
    async navigateToCoupleHome(uid) {
      const docRef = doc(db, "Users", uid);
      const docSnap = await getDoc(docRef);
      const cid = docSnap.data().cid;
      if (cid) {
        this.$router.push(`/couple/${cid}`);
        this.menu_visible = false;
      } else {
        cLogger.error(404, `couple id not found`);
      }
    },
    staticNavTo(dest) {
      this.$router.push(dest);
    },
    async navigateToCoupleAnalysis(uid) {
      const docRef = doc(db, "Users", uid);
      const docSnap = await getDoc(docRef);
      const cid = docSnap.data().cid;
      if (cid) {
        this.$router.push(`/couple/${cid}/analysis`);
      } else {
        cLogger.error(404, `カップルIDが見つかりません`);
      }
    },
    logout() {
      signOut(auth)
        .then(() => {
          // this.showLogoutToast = true;
          this.$router.push(`/`);
          this.menu_visible = false;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  created() {
    // this.login();
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        this.currentUser = user;
        console.log("currently logged-in user: ", user);
      } else {
        this.currentUser = null;
        console.log("not logged in", user);
      }
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  max-width: 500px;
}

.header-view {
  max-width: 100%;
}
.router-view {
  margin-top: 60px;
  max-width: 100%;
}

.ad-container {
  z-index: 5;
  position: absolute;
  width: 100%;
  max-width: 500px;
  top: 130px;
  background-color: rgba(255, 255, 255, 0.3);
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1.5px solid #777777;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.balance-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.balance-card {
  width: 30%;
  margin: 3px 3px;
}
.login-button {
  margin: 50px auto;
}
</style>