import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'

// Vuetify3
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import 'vuetify/dist/vuetify.min.css'

const app = createApp(App);
const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
	theme: { defaultTheme: 'light' },
})


app.use(router);
app.use(vuetify);

app.mount('#app');
