<template>
  <div class="couple-home-view">
    <div v-if="coupleData">
      <div v-if="coupleData.isActivated">
        <div class="profile-card-container">
          <v-card class="mx-auto my-4 pt-4">
            <div class="profile-header-container mb-3">
              <div class="profile-user-container" v-if="userData_partner1">
                <div class="profile-image-contianer">
                  <div class="profile-image-wrapper">
                    <img v-if="userData_partner1" :src="userData_partner1.image" alt="プロフィール画像" class="profile-image" />
                  </div>
                </div>
                <div class="name-wrapper my-2">
                  <p>{{ userData_partner1.name }}</p>
                  <v-card-text>¥ {{ userData_partner1.expense }}</v-card-text>
                </div>
              </div>
              <div class="comparison-wrapper">
                <div v-if="unsettledBalance.partner1.debt > 0">
                  <div>¥ {{ integerize(unsettledBalance.partner1.debt) }}</div>
                  <div>
                    <SvgIcon type="mdi" :path="iconPath.mdiArrowRightBoldOutline" :color="cColors.primary_dark_1">
                    </SvgIcon>
                  </div>
                </div>
                <div v-else-if="unsettledBalance.partner1.debt < 0">
                  <div>¥ {{ integerize(unsettledBalance.partner2.debt) }}</div>
                  <div>
                    <SvgIcon type="mdi" :path="iconPath.mdiArrowLeftBoldOutline" :color="cColors.primary_dark_1">
                    </SvgIcon>
                  </div>
                </div>
                <div v-else>
                  <div>¥ {{ unsettledBalance.partner1.debt }}</div>
                  <div>
                    <SvgIcon type="mdi" :path="iconPath.mdiEqual" :color="cColors.primary_dark_1"></SvgIcon>
                  </div>
                </div>
              </div>
              <div class="profile-user-container" v-if="userData_partner2">
                <div class="profile-image-contianer">
                  <div class="profile-image-wrapper">
                    <img :src="userData_partner2.image" alt="プロフィール画像" class="profile-image" />
                  </div>
                </div>
                <div class="name-wrapper my-2">
                  <p>{{ userData_partner2.name }}</p>
                  <v-card-text>¥ {{ userData_partner2.expense }}</v-card-text>
                </div>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <div>
                <v-btn @click="openPayoff" :color="cColors.primary" rounded="xl">
                  <SvgIcon type="mdi" :path="iconPath.mdiCashFast"></SvgIcon>
                  <p>精算する</p>
                </v-btn>
              </div>

              <v-spacer></v-spacer>
              <v-btn @click="openSettings" :color="cColors.gray_light_1" variant="plain" rounded="xl">
                <SvgIcon type="mdi" :path="iconPath.mdiCog"></SvgIcon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-dialog v-model="payoff_visible" width="90vw">
            <v-card class="px-8">
              <v-card-text>精算を行う月を選択してください</v-card-text>
              <v-card-actions v-for="month in purchasedMonth" :key="month">
                <v-btn @click="payOff(month)" :color="cColors.primary" stack width="100%">
                  <!-- <SvgIcon type="mdi" :path="iconPath.mdiCashFast"></SvgIcon> -->
                  <p>{{ month }}</p>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="settings_visible" width="90vw">
            <v-card class="px-8">
              <div class="payment-input-field-wrapper">
                <p>デフォルトの負担比率</p>
                <div class="d-flex justify-content-center my-4">
                  <v-text-field type="number" :label="coupleData.partner1.name" v-model="coupleData.partner1.ratio"
                    :rules="inputRule_paymentRatio"></v-text-field>
                  <div>
                    <p>:</p>
                  </div>
                  <v-text-field type="number" :label="coupleData.partner2.name" v-model="coupleData.partner2.ratio"
                    :rules="inputRule_paymentRatio"></v-text-field>
                </div>
                <v-switch v-model="coupleData.subscriptions.isEnabled" :color="cColors.primary"
                  label="サブスク管理をする"></v-switch>
                <v-switch v-model="coupleData.budgets.isEnabled" :color="cColors.primary" label="予算管理をする"></v-switch>
                <div v-if="coupleData.budgets.isEnabled">
                  <v-card class="pa-4">
                    <v-card-text>全体予算: ¥
                      {{
                        Number(coupleData.budgets.food) +
                        Number(coupleData.budgets.social) +
                        Number(coupleData.budgets.house) +
                        Number(coupleData.budgets.entertainment) +
                        Number(coupleData.budgets.dailyGoods) +
                        Number(coupleData.budgets.utility)
                      }}</v-card-text>

                    <v-card-text>カテゴリ別予算</v-card-text>
                    <v-text-field class="my-2" label="食費" type="number" hide-details="auto"
                      v-model="coupleData.budgets.food" :color="cColors.accent.dark_1" clearable></v-text-field>
                    <v-text-field class="my-2" label="娯楽費" type="number" hide-details="auto"
                      v-model="coupleData.budgets.entertainment" :color="cColors.accent.dark_1" clearable></v-text-field>
                    <v-text-field class="my-2" label="日用品" type="number" hide-details="auto"
                      v-model="coupleData.budgets.dailyGoods" :color="cColors.accent.dark_1" clearable></v-text-field>
                    <v-text-field class="my-2" label="交際費" type="number" hide-details="auto"
                      v-model="coupleData.budgets.social" :color="cColors.accent.dark_1" clearable></v-text-field>
                    <v-text-field class="my-2" label="家賃" type="number" hide-details="auto"
                      v-model="coupleData.budgets.house" :color="cColors.accent.dark_1" clearable></v-text-field>
                    <v-text-field class="my-2" label="光熱費" type="number" hide-details="auto"
                      v-model="coupleData.budgets.utility" :color="cColors.accent.dark_1" clearable></v-text-field>
                  </v-card>
                </div>
              </div>
              <v-card-actions>
                <v-btn :color="cColors.primary" block @click="saveSettings">
                  <SvgIcon type="mdi" :path="iconPath.mdiContentSaveCheck"></SvgIcon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="subscriptionCard_visible" width="90vw">
            <v-card class="px-8 py-4">
              <v-card-title>サブスクリプションを記録</v-card-title>
              <div class="payment-input-field-wrapper">
                <v-text-field label="名前" hide-details="auto" v-model="subscription.name" :color="cColors.accent.dark_1"
                  clearable></v-text-field>
              </div>
              <div class="payment-input-field-wrapper">
                <v-text-field type="number" label="金額" hide-details="auto" v-model="subscription.amount"
                  :color="cColors.accent.dark_1" clearable></v-text-field>
              </div>
              <div class="payment-input-field-wrapper">
                <p>負担比率</p>
                <div class="d-flex justify-content-center">
                  <v-text-field type="number" :label="coupleData.partner1.name" v-model="subscription.ratio.partner1"
                    :rules="inputRule_paymentRatio"></v-text-field>
                  <div>
                    <p>:</p>
                  </div>
                  <v-text-field type="number" :label="coupleData.partner2.name" v-model="subscription.ratio.partner2"
                    :rules="inputRule_paymentRatio"></v-text-field>
                </div>
              </div>
              <div class="payment-input-field-wrapper">
                <p>定期的に払う人</p>
                <v-radio-group inline v-model="subscription.paiedBy">
                  <v-radio :label="coupleData.partner1.name" value="partner1" :color="cColors.accent.dark_1"></v-radio>
                  <v-radio :label="coupleData.partner2.name" value="partner2" :color="cColors.accent.dark_1"></v-radio>
                </v-radio-group>
              </div>
              <div class="payment-input-field-wrapper">
                <v-text-field label="繰り返し" hide-details="auto" v-model="subscription.cron" :color="cColors.accent.dark_1"
                  clearable></v-text-field>
              </div>
              <div class="payment-input-field-wrapper">
                <v-combobox label="カテゴリー" v-model="subscription.category" :color="cColors.accent.dark_1" :items="[
                  '食費',
                  '交際費',
                  '娯楽費',
                  '日用品費',
                  '光熱費',
                  '家賃',
                ]"></v-combobox>
              </div>
              <div class="payment-input-field-wrapper">
                <v-text-field label="コメント" hide-details="auto" v-model="subscription.detail"
                  :color="cColors.accent.dark_1" clearable></v-text-field>
              </div>
              <v-card-actions>
                <v-btn :color="cColors.primary" block @click="saveSubscription">
                  <SvgIcon type="mdi" :path="iconPath.mdiContentSaveCheck"></SvgIcon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="paymentCard_visible" width="90vw">
            <v-card class="px-8 py-4">
              <v-card-title>支出を記録</v-card-title>
              <div class="payment-input-field-wrapper">
                <v-text-field label="名前" hide-details="auto" v-model="payment.name" :color="cColors.accent.dark_1"
                  clearable></v-text-field>
              </div>
              <div class="payment-input-field-wrapper">
                <v-text-field type="number" label="金額" hide-details="auto" v-model="payment.amount"
                  :color="cColors.accent.dark_1" clearable></v-text-field>
              </div>
              <div class="payment-input-field-wrapper">
                <p>負担比率</p>
                <div class="d-flex justify-content-center">
                  <v-text-field type="number" :label="coupleData.partner1.name" v-model="payment.ratio.partner1"
                    :rules="inputRule_paymentRatio"></v-text-field>
                  <div>
                    <p>:</p>
                  </div>
                  <v-text-field type="number" :label="coupleData.partner2.name" v-model="payment.ratio.partner2"
                    :rules="inputRule_paymentRatio"></v-text-field>
                </div>
              </div>
              <div class="payment-input-field-wrapper">
                <p>払った人</p>
                <v-radio-group inline v-model="payment.paiedBy">
                  <v-radio :label="coupleData.partner1.name" value="partner1" :color="cColors.accent.dark_1"></v-radio>
                  <v-radio :label="coupleData.partner2.name" value="partner2" :color="cColors.accent.dark_1"></v-radio>
                </v-radio-group>
              </div>
              <div class="payment-input-field-wrapper">
                <v-combobox label="カテゴリー" v-model="payment.category" :color="cColors.accent.dark_1" :items="[
                  '食費',
                  '交際費',
                  '娯楽費',
                  '日用品費',
                  '光熱費',
                  '家賃',
                ]"></v-combobox>
              </div>
              <div class="payment-input-field-wrapper">
                <v-text-field label="コメント" hide-details="auto" v-model="payment.detail" :color="cColors.accent.dark_1"
                  clearable></v-text-field>
              </div>
              <v-card-actions>
                <v-btn :color="cColors.primary" block @click="savePayment">
                  <SvgIcon type="mdi" :path="iconPath.mdiContentSaveCheck"></SvgIcon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div>
            <v-card class="pt-1">
              <v-tabs v-model="shownTab_balanceConsole" :color="cColors.accent.dark_1" align-tabs="center">
                <v-tab :value="1">{{ coupleData.partner1.name }}</v-tab>
                <v-tab :value="0">全体</v-tab>
                <v-tab :value="2">{{ coupleData.partner2.name }}</v-tab>
              </v-tabs>
              <BalanceConsole v-if="shownTab_balanceConsole == 1" :balanceData="unsettledBalance.partner1"
                :cImages="cImages" />
              <BalanceConsole v-if="shownTab_balanceConsole == 0" :balanceData="unsettledBalance.whole"
                :cImages="cImages" />
              <BalanceConsole v-if="shownTab_balanceConsole == 2" :balanceData="unsettledBalance.partner2"
                :cImages="cImages" />
            </v-card>
            <!-- <h4>支出額</h4> -->
            <v-divider></v-divider>
          </div>
        </div>

        <div>
          <v-card class="mt-4 pt-1">
            <div>
              <v-tabs v-model="shownTab_payments" :color="cColors.accent.dark_1" align-tabs="center">
                <v-tab :value="0">明細({{ coupleData_unsettledPayments.length }})</v-tab>
                <v-tab :value="1" v-if="coupleData.budgets.isEnabled">予算</v-tab>
                <v-tab :value="2" v-if="coupleData.subscriptions.isEnabled" @click="getSubscriptions">サブスク({{
                  coupleData_subscriptions.length == 0
                  ? "-"
                  : coupleData_subscriptions.length
                }})</v-tab>
              </v-tabs>
            </div>
            <div v-if="shownTab_payments == 0" class="px-4">
              <div v-for="payment in coupleData_unsettledPayments" :key="payment.pid">
                <PaymentCard :payment="payment" :isReadOnly="false" :cColors="cColors" :coupleData="coupleData"
                  :cImages="cImages" @onDelete="deletePayment(payment)" @onEdit="updatePayment(payment)"
                  @onSelect="updateDebt(payment)" />
              </div>
            </div>
            <div v-if="shownTab_payments == 1 && coupleData.budgets.isEnabled" class="px-4 pb-4">
              <div v-for="(budget, index) in coupleData.budgets" :key="index">
                <div v-if="index != 'isEnabled'">
                  <v-row class="py-4 my-0">
                    <v-col cols="4">
                      <v-card-text>{{
                        translate(index, "en", "ja")
                      }}</v-card-text>
                    </v-col>
                    <v-col cols="8" class="my-0">
                      <v-progress-linear :model-value="(unsettledBalance.whole[index] * 100) / budget
                          " height="25" :color="cColors.graph[index]" class="justify-center">
                        <div>
                          <p>
                            ¥ {{ unsettledBalance.whole[index] }} / {{ budget }}
                          </p>
                        </div>
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div v-if="shownTab_payments == 2" class="px-4">
              <div v-if="coupleData_subscriptions.length == 0">
                登録されているサブスクが存在しません
              </div>
              <div v-else v-for="subscription in coupleData_subscriptions" :key="subscription.subid">
                <SubscriptionCard :subscription="subscription" :isReadOnly="false" :cColors="cColors"
                  :coupleData="coupleData" :cImages="cImages" @onEdit="updateSubscription(subscription)"
                  @onDelete="deleteSubscription(subscription)" />
              </div>
            </div>
          </v-card>
          <v-divider></v-divider>
        </div>

        <div class="float-btn-contianer">
          <div class="float-btn-wrapper" @click="createPayment">
            <SvgIcon type="mdi" :path="iconPath.mdiPenPlus" :color="cColors.primary"></SvgIcon>
          </div>
        </div>
      </div>
      <div v-else>
        <ErrorMessenger message="お財布が作成されていません" hint="QRを読み込み、パートナー接続をしてください" :cColors="cColors" />
      </div>
    </div>
  </div>
</template>

<script>
import CouplicaLogger from "../../common/CouplicaLogger";
const cLogger = new CouplicaLogger();

import Payment from "../../common/Payment";
import PaymentCard from "../components/PaymentCard.vue";
import BalanceConsole from "../components/BalanceConsole.vue";
import ErrorMessenger from "../components/ErrorMessenger.vue";
import Subscription from "../../common/Subscription";
import SubscriptionCard from "../components/SubscriptionCard.vue";

const translateText = require("../../common/translateText");

import { db } from "../../firebase";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  deleteDoc,
  increment,
  query,
  orderBy,
} from "firebase/firestore";

import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiCashFast,
  mdiSwapHorizontalBold,
  mdiChevronDown,
  mdiChevronUp,
  mdiCog,
  mdiContentSaveCheck,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiEqual,
  mdiHomeAccount,
  mdiLinkVariant,
  mdiPenPlus,
} from "@mdi/js";

export default {
  props: ["currentUser", "cColors", "cImages"],
  components: {
    SvgIcon,
    PaymentCard,
    BalanceConsole,
    ErrorMessenger,
    SubscriptionCard,
  },
  data: function () {
    return {
      paymentCard_visible: false,
      subscriptionCard_visible: false,
      cid: null,
      coupleData: null,
      coupleData_unsettledPayments: [],
      coupleData_settledPayments: [],
      userData_partner1: null,
      userData_partner2: null,
      payment: null,
      subscription: null,
      coupleData_subscriptions: [],
      inputRule_paymentRatio: [
        (value) => {
          if (value) return true;
          return "値が正しくありません";
        },
      ],
      shownTab_balanceConsole: 0,
      shownTab_payments: 0,
      iconPath: {
        mdiCashFast: mdiCashFast,
        mdiSwapHorizontalBold: mdiSwapHorizontalBold,
        mdiChevronDown: mdiChevronDown,
        mdiChevronUp: mdiChevronUp,
        mdiCog: mdiCog,
        mdiContentSaveCheck: mdiContentSaveCheck,
        mdiArrowRightBoldOutline: mdiArrowRightBoldOutline,
        mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
        mdiEqual: mdiEqual,
        mdiHomeAccount: mdiHomeAccount,
        mdiLinkVariant: mdiLinkVariant,
        mdiPenPlus: mdiPenPlus,
      },
      payoff_visible: false,
      settings_visible: false,
      subscription_visible: false,
      purchasedMonth: [],
      unsettledBalance: {
        partner1: {
          debt: 0,
          total: 0,
          food: 0,
          entertainment: 0,
          dailyGoods: 0,
          social: 0,
          house: 0,
          utility: 0,
        },
        partner2: {
          debt: 0,
          total: 0,
          food: 0,
          entertainment: 0,
          dailyGoods: 0,
          social: 0,
          house: 0,
          utility: 0,
        },
        whole: {
          total: 0,
          food: 0,
          entertainment: 0,
          dailyGoods: 0,
          social: 0,
          house: 0,
          utility: 0,
        },
      },
      statisticsData: {
        settledBalance: {
          whole: {
            total: 0,
            food: 0,
            entertainment: 0,
            dailyGoods: 0,
            social: 0,
            house: 0,
            utility: 0,
          },
          partner1: {
            total: 0,
            food: 0,
            entertainment: 0,
            dailyGoods: 0,
            social: 0,
            house: 0,
            utility: 0,
          },
          partner2: {
            total: 0,
            food: 0,
            entertainment: 0,
            dailyGoods: 0,
            social: 0,
            house: 0,
            utility: 0,
          },
        },
        lastSettledAt: new Date(),
        uids: null,
      },
    };
  },
  methods: {
    async getCouple() {
      const docRef_couple = doc(db, "Couples", this.$route.params.cid);
      const docSnapshot = await getDoc(docRef_couple);
      this.coupleData = docSnapshot.data();
      if (this.coupleData.isActivated) {
        await this.getUserData();
        await this.getUnsettledPayments();
        this.caluculateDebt(this.coupleData_unsettledPayments);
        this.getExpense();
        cLogger.info(200, "未精算データの取得処理が正常完了しました");
      } else {
        cLogger.error(500, "カップルエンティティが有効化されていません");
      }
    },
    async getUnsettledPayments() {
      const colRef_unsettledPayments = collection(
        db,
        "Couples",
        this.$route.params.cid,
        "payments_unsettled"
      );
      const q = query(colRef_unsettledPayments, orderBy("purchasedAt", "desc"));
      const docsSnapshot = await getDocs(q);
      docsSnapshot.forEach((doc) => {
        const p = doc.data();
        p.isSelected = true;
        this.coupleData_unsettledPayments.push(p);
        if (this.purchasedMonth.indexOf(p.purchasedMonth) === -1) {
          this.purchasedMonth.push(p.purchasedMonth);
        }
      });
    },
    getExpense() {
      const partners = ["partner1", "partner2"];
      partners.forEach((partner) => {
        const unsettledPayments_filtered =
          this.coupleData_unsettledPayments.filter((item) => {
            return item.paiedBy == partner;
          });

        this[`userData_${partner}`].expense = unsettledPayments_filtered.reduce(
          function (sum, element) {
            return sum + Number(element.amount);
          },
          0
        );
      });
    },
    caluculateDebt(payments) {
      this.resetUnsettledBalance();
      payments.forEach((payment) => {
        this.updateUnsettledPaymentAmount(this.unsettledBalance, payment);
      });
    },
    resetUnsettledBalance() {
      const resetPartner = {
        debt: 0,
        total: 0,
        food: 0,
        entertainment: 0,
        dailyGoods: 0,
        social: 0,
        house: 0,
        utility: 0,
      };
      const resetWhole = {
        total: 0,
        food: 0,
        entertainment: 0,
        dailyGoods: 0,
        social: 0,
        house: 0,
        utility: 0,
      };
      this.unsettledBalance.partner1 = { ...resetPartner };
      this.unsettledBalance.partner2 = { ...resetPartner };
      this.unsettledBalance.whole = { ...resetWhole };
    },
    updateUnsettledPaymentAmount(target, payment) {
      if (!payment.isSelected) {
        return null;
      }
      if (payment.paiedBy == "partner1") {
        // partner1が払っている場合
        // partner2が払うべき金額 = 全体金額 - partner1が払うべき金額
        let diff_payment_expectedPayment_p1 = Number(
          payment.amount -
          (payment.amount * Number(payment.ratio.partner1)) /
          (Number(payment.ratio.partner1) + Number(payment.ratio.partner2))
        );
        // console.log("[TEST] this should always be true: ", diff_payment_expectedPayment_p1 >= 0);
        // 相手方の未精算残高を増やす
        target.partner2.debt += Number(diff_payment_expectedPayment_p1);
        // 自分方の未精算残高を減らす
        target.partner1.debt -= Number(diff_payment_expectedPayment_p1);
      } else {
        // partner2が払っている場合
        // partner1が払うべき金額 = 全体金額 - partner2が払うべき金額
        let diff_payment_expectedPayment_p2 = Number(
          payment.amount -
          (payment.amount * Number(payment.ratio.partner2)) /
          (Number(payment.ratio.partner1) + Number(payment.ratio.partner2))
        );
        // console.log("[TEST] this should always be true: ", diff_payment_expectedPayment_p2 >= 0);
        target.partner1.debt += Number(diff_payment_expectedPayment_p2);
        target.partner2.debt -= Number(diff_payment_expectedPayment_p2);
      }

      // 支払い比率の定義
      const ratio_partner1 =
        Number(payment.ratio.partner1) /
        (Number(payment.ratio.partner1) + Number(payment.ratio.partner2));
      const ratio_partner2 =
        Number(payment.ratio.partner2) /
        (Number(payment.ratio.partner1) + Number(payment.ratio.partner2));

      const categories = {
        娯楽費: "entertainment",
        日用品費: "dailyGoods",
        交際費: "social",
        食費: "food",
        家賃: "house",
        光熱費: "utility",
      };

      // カテゴリ別の支出集計
      const categoryKey = categories[payment.category];

      function conditionalIncrement(target, key, value) {
        target[key] += Number(Math.floor(value));
      }

      if (categoryKey) {
        conditionalIncrement(target.whole, categoryKey, payment.amount);
        conditionalIncrement(
          target.partner1,
          categoryKey,
          payment.amount * ratio_partner1
        );
        conditionalIncrement(
          target.partner2,
          categoryKey,
          payment.amount * ratio_partner2
        );
      } else {
        console.log(
          "不明なカテゴリです。詳細を調査してください。\npayment: \n",
          payment
        );
      }
      // 合計の積算
      conditionalIncrement(target.whole, "total", payment.amount);
      conditionalIncrement(
        target.partner1,
        "total",
        payment.amount * ratio_partner1
      );
      conditionalIncrement(
        target.partner2,
        "total",
        payment.amount * ratio_partner2
      );
      return target;
    },
    async getUserData() {
      try {
        const docRef_user1 = doc(db, "Users", this.coupleData.uids[0]);
        const docSnapshot_user1 = await getDoc(docRef_user1);
        this.userData_partner1 = docSnapshot_user1.data();

        const docRef_user2 = doc(db, "Users", this.coupleData.uids[1]);
        const docSnapshot_user2 = await getDoc(docRef_user2);
        this.userData_partner2 = docSnapshot_user2.data();
      } catch (err) {
        cLogger.error(500, `${err}`);
      }
    },
    createPayment() {
      this.payment = new Payment(this.coupleData, this.$route.params.cid);
      this.paymentCard_visible = true;
    },
    updatePayment(payment) {
      this.payment = payment;
      this.paymentCard_visible = true;
    },
    async deletePayment(payment) {
      const confirm_delete = confirm(`${payment.name}を削除しますか？`);
      if (confirm_delete) {
        try {
          await deleteDoc(
            doc(
              db,
              "Couples",
              this.$route.params.cid,
              "payments_unsettled",
              payment.pid
            )
          );
          cLogger.info(200, `Payment ${payment.name} successfully deleted`);
        } catch (err) {
          cLogger.error(500, err.message);
        }
        this.reload();
      } else {
        alert("キャンセルしました");
      }
    },
    async savePayment() {
      const paymentAdded = await setDoc(
        doc(
          db,
          "Couples",
          this.$route.params.cid,
          "payments_unsettled",
          this.payment.pid
        ),
        Object.assign({}, this.payment)
      );
      cLogger.info(201, `${paymentAdded}`);
      this.reload();
    },
    async payOff(month) {
      const settleMonth = month.replaceAll("/", "");
      const confirmed = window.confirm(`${month} の支払いについて、本当に清算しますか？`);

      if (confirmed) {
        this.statisticsData.uids = this.coupleData.uids;

        try {
          // statisticsにデータを反映する
          const settlementId = `set_${Math.random().toString(32).substring(2)}`;
          const updateData_statistics = {
            s_id: `${this.$route.params.cid}_${settleMonth}`,
            settledBalance: {
              whole: {
                total: increment(this.unsettledBalance.whole.total),
                food: increment(this.unsettledBalance.whole.food),
                entertainment: increment(this.unsettledBalance.whole.entertainment),
                dailyGoods: increment(this.unsettledBalance.whole.dailyGoods),
                social: increment(this.unsettledBalance.whole.social),
                house: increment(this.unsettledBalance.whole.house),
                utility: increment(this.unsettledBalance.whole.utility),
              },
              partner1: {
                total: increment(this.unsettledBalance.partner1.total),
                food: increment(this.unsettledBalance.partner1.food),
                entertainment: increment(this.unsettledBalance.partner1.entertainment),
                dailyGoods: increment(this.unsettledBalance.partner1.dailyGoods),
                social: increment(this.unsettledBalance.partner1.social),
                house: increment(this.unsettledBalance.partner1.house),
                utility: increment(this.unsettledBalance.partner1.utility),
              },
              partner2: {
                total: increment(this.unsettledBalance.partner2.total),
                food: increment(this.unsettledBalance.partner2.food),
                entertainment: increment(this.unsettledBalance.partner2.entertainment),
                dailyGoods: increment(this.unsettledBalance.partner2.dailyGoods),
                social: increment(this.unsettledBalance.partner2.social),
                house: increment(this.unsettledBalance.partner2.house),
                utility: increment(this.unsettledBalance.partner2.utility),
              },
            },
            lastSettledAt: new Date(),
            settlement_ref: settlementId,
            uids: this.coupleData.uids,
          };
          await setDoc(
            doc(
              db,
              "Couples",
              this.$route.params.cid,
              "statistics",
              `${this.$route.params.cid}_${settleMonth}`
            ),
            updateData_statistics,
            { merge: true }
          );
          await setDoc(
            doc(
              db,
              "Couples",
              this.$route.params.cid,
              "settlements",
              settlementId
            ),
            {
              from: this.unsettledBalance.partner1.debt > 0 ? 'partner1' : 'partner2',
              to: this.unsettledBalance.partner1.debt > 0 ? 'partner2' : 'partner1',
              amount: this.unsettledBalance.partner1.debt > 0 ? this.integerize(this.unsettledBalance.partner1.debt) : this.integerize(this.unsettledBalance.partner2.debt),
              uids: this.coupleData.uids
            }
          );

          let i = 0;
          console.log(`counting up to: ${this.coupleData_unsettledPayments.length}`)

          this.coupleData_unsettledPayments.forEach(async (payment) => {
            i++;
            // console.log(`${i-1} -> ${i}`);

            // 精算月を追加
            payment.settledAt = settleMonth;
            if (payment.purchasedMonth === month) {
              try {
                await setDoc(
                  doc(
                    db,
                    "Couples",
                    this.$route.params.cid,
                    "payments_settled",
                    payment.pid
                  ),
                  payment
                );
                await deleteDoc(
                  doc(
                    db,
                    "Couples",
                    this.$route.params.cid,
                    "payments_unsettled",
                    payment.pid
                  )
                );

                // 最後の要素を判定し、最後であれば処理完了なのでリロード
                // その月でないデータが含まれるとズレる、、
                if (i == this.coupleData_unsettledPayments.length) {
                  setTimeout(this.payoff_visible = false, 6000);
                }
              } catch (err) {
                alert(err);
              }
            }
          });
        } catch (e) {
          alert(e);
        }
      } else {
        window.alert("キャンセルしました");
        this.payoff_visible = false;
        return null;
      }
    },
    reload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    integerize(num) {
      return Math.round(num);
    },
    openSettings() {
      this.settings_visible = true;
    },
    openPayoff() {
      this.payoff_visible = true;
    },
    updateDebt(payment) {
      payment.isSelected = !payment.isSelected;
      this.caluculateDebt(this.coupleData_unsettledPayments);
    },
    async saveSettings() {
      try {
        const updateData_couple = {
          "partner1.ratio": Number(this.coupleData.partner1.ratio),
          "partner2.ratio": Number(this.coupleData.partner2.ratio),
          budgets: {
            isEnabled: this.coupleData.budgets.isEnabled,
            food: this.coupleData.budgets.food,
            social: this.coupleData.budgets.social,
            house: this.coupleData.budgets.house,
            dailyGoods: this.coupleData.budgets.dailyGoods,
            utility: this.coupleData.budgets.utility,
            entertainment: this.coupleData.budgets.entertainment,
          },
          subscriptions: {
            isEnabled: this.coupleData.subscriptions.isEnabled,
          },
        };
        const docRef_couple = doc(db, "Couples", this.$route.params.cid);
        await updateDoc(docRef_couple, updateData_couple);
        cLogger.info(200, "お財布データの更新が完了しました");
        this.settings_visible = false;
      } catch (err) {
        cLogger.error(500, err.message);
      }
    },
    navigateToProfile() {
      this.$router.push(`/profile/${this.currentUser.uid}`);
    },
    translate(word) {
      return translateText(word, "en", "ja");
    },
    createSubscription() {
      this.subscription = new Subscription(
        this.coupleData,
        this.$route.params.cid
      );
      this.subscriptionCard_visible = true;
    },
    updateSubscription(subscription) {
      this.subscription = subscription;
      this.subscriptionCard_visible = true;
    },
    async deleteSubscription(subscription) {
      const confirm_delete = confirm(`${subscription.name}を削除しますか？`);
      if (confirm_delete) {
        try {
          await deleteDoc(
            doc(
              db,
              "Couples",
              this.$route.params.cid,
              "subscriptions",
              subscription.subid
            )
          );
          cLogger.info(
            200,
            `サブスクリプション: ${subscription.name} が正常に削除されました`
          );
        } catch (err) {
          cLogger.error(500, err.message);
        }
        this.reload();
      } else {
        alert("キャンセルしました");
      }
    },
    async saveSubscription() {
      try {
        await setDoc(
          doc(
            db,
            "Couples",
            this.$route.params.cid,
            "subscriptions",
            this.subscription.subid
          ),
          Object.assign({}, this.subscription)
        );
        cLogger.info(201, `サブスクの登録が完了しました`);
        this.reload();
      } catch (err) {
        cLogger.error(500, err.message);
      }
    },
    async getSubscriptions() {
      if (this.coupleData_subscriptions.length == 0) {
        const colRef_unsettledPayments = collection(
          db,
          "Couples",
          this.$route.params.cid,
          "subscriptions"
        );
        const docsSnapshot = await getDocs(colRef_unsettledPayments);
        docsSnapshot.forEach((doc) => {
          this.coupleData_subscriptions.push(doc.data());
        });
      } else {
        cLogger.info(0, "すでにサブスクの情報が取得されています");
      }
    },
  },
  created: function () {
    this.getCouple();
  },
};
</script>

<style scoped>
.couple-home-view {
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("../assets/top.jpg");
  margin: 65px auto auto 0px;
  padding: 20px;
  text-align: center;
  background-attachment: fixed;

  display: flex;
  flex-direction: column;
}

.profile-card-container {
  margin-top: 100px;
}

.profile-header-container {
  display: flex;
  justify-content: center;
}

.profile-user-container {
  margin: auto;
  justify-content: center;
}

.profile-image-wrapper {
  width: 75px;
  height: 75px;
}

.comparison-wrapper {
  width: 20%;
  height: 100%;
  margin-top: 10%;
}

.float-btn-contianer {
  position: relative;
  width: 100%;
  height: 100%;
}

.float-btn-wrapper {
  position: fixed;
  bottom: 5vh;
  right: 10vw;
  width: 70px;
  height: 70px;
  background-color: #f0f3bd;
  /* Green */
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  /* Flexboxを使用 */
  align-items: center;
  /* 縦方向中央揃え */
  justify-content: center;
  /* 横方向中央揃え */
}

/* .float-btn-wrapper:hover {
    background-color: #eab841;
} */
.float-btn-wrapper:active {
  background-color: #eab841;
}

.payment-input-field-wrapper {
  margin: 10px 0px;
}
</style>